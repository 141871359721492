<template>
    <b-row>
        <b-col md="12">
            <b-card>
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>

                            <!-- Generate Coupon Code Button -->
                            <b-col md="12">
                                <b-button
                                    @click="generateDicountCouponCode"
                                    size="sm"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                >
                                    GENERAR UN CÓDIGO DE CUPÓN
                                </b-button>
                                <hr>
                            </b-col>

                            <!-- Code -->
                            <b-col md="4">
                                <b-form-group
                                    label="Código"
                                    label-for="code">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="código de cupón"
                                    >
                                        <b-form-input
                                            id="code"
                                            v-model="code"
                                            placeholder="Código de cupón ..."
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Discount Coupon Type -->
                            <b-col md="4">
                                <b-form-group
                                    label="Seleccionar tipo de descuento"
                                    label-for="discount_coupon_type">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tipo de descuento"
                                    >
                                        <v-select
                                            id="discount_coupon_type"
                                            v-model="type"
                                            :state="errors.length > 0 ? false : null"
                                            :options="types"
                                            label="name"
                                        />

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Amount -->
                            <b-col md="4">
                                <b-form-group
                                    label="Importe"
                                    label-for="amount">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="importe de cupón"
                                    >
                                        <b-form-input
                                            id="amount"
                                            v-model="amount"
                                            placeholder="importe del cupón ..."
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Usage Per User Quantity -->
                            <b-col md="4">
                                <b-form-group
                                    label="Límite de uso por usuario"
                                    label-for="usagePerUserQuantity">

                                    <b-form-input
                                        type="number"
                                        id="usagePerUserQuantity"
                                        v-model="usagePerUserQuantity"
                                        placeholder="Límite de uso por usuario ..."
                                    />

                                </b-form-group>
                            </b-col>

                            <!-- Usage Per Coupon Quantity -->
                            <b-col md="4">
                                <b-form-group
                                    label="Límite de uso por cupón"
                                    label-for="usagePerCouponQuantity">

                                    <b-form-input
                                        type="number"
                                        id="usagePerCouponQuantity"
                                        v-model="usagePerCouponQuantity"
                                        placeholder="Límite de uso por cupón ..."
                                    />

                                </b-form-group>
                            </b-col>

                            <!-- Expiry Date -->
                            <b-col md="4">
                                <b-form-group
                                    label="Fecha de caducidad"
                                    label-for="expiry_date">

                                    <flat-pickr
                                        id="expiry_date"
                                        v-model="expiryDate"
                                        class="form-control"
                                        :config="config"
                                        placeholder="YYYY-MM-DD"
                                    />

                                </b-form-group>
                            </b-col>

                            <!-- Minimum Cost -->
                            <b-col md="4">
                                <b-form-group
                                    label="Gasto mínimo"
                                    label-for="minimumCost">

                                    <b-form-input
                                        type="number"
                                        id="minimumCost"
                                        v-model="minimumCost"
                                        placeholder="Gasto mínimo ..."
                                    />

                                </b-form-group>
                            </b-col>
                            
                            <!-- Products on Offer -->
                            <b-col md="4">
                                <b-form-group
                                    label="¿Aplica para productos con descuento?"
                                    label-for="products_on_offer">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="productos con descuento"
                                    >
                                        <v-select
                                            id="products_on_offer"
                                            v-model="productsOnOffer"                                            
                                            :reduce="(option) => option.value"
                                            :state="errors.length > 0 ? false : null"
                                            :options="optionsOffer"
                                            label="label"
                                        />

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                            <b-col md="12">
                                <b-form-checkbox
                                    class="mb-2 mt-1"
                                    v-model="freeDelivery"
                                    name="freeDelivery"
                                >
                                    <strong>¿PERMITIR EL ENVÍO GRATUITO?</strong>
                                </b-form-checkbox>
                            </b-col>

                            <!-- Submit Button -->
                            <b-col cols="12">
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    @click.prevent="validationForm"
                                >
                                    AGREGAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import { BFormInput, BFormTextarea, BFormGroup, BForm, BRow, BCol, BButton, BCard, BCardText, BImg, BFormCheckbox } from 'bootstrap-vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import Toast from 'vue-toastification';
    import vSelect from 'vue-select';
    import cc  from 'coupon-code';
    import axios from 'axios';
    import Vue from 'vue';

    Vue.use(Toast);

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BFormCheckbox,
            BForm,
            BRow,
            BCol,
            BCard,
            BButton,
            BImg,
            BCardText,
            vSelect,
            flatPickr
        },
        directives: {
            Ripple
        },
        data() {
            return {
                required,
                types: [],
                optionsOffer: [{
                    label: 'Sí',
                    value: true
                },{
                    label: 'No',
                    value: false
                }],

                code: '',
                type: null,
                amount: 0,
                productsOnOffer: false,
                usagePerUserQuantity: null,
                usagePerCouponQuantity: null,
                expiryDate: null,
                minimumCost: null,
                freeDelivery: false,
                config: {
                    enableTime: false,
                    locale: Spanish
                }
            }
        },
        beforeMount (){
            this.getDiscountCouponTypes();
        },
        methods: {
            async getDiscountCouponTypes (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon-types/select`);
                this.types = response.data;
            },
            generateDicountCouponCode (){
                this.code = cc.generate(); 
            },
            validationForm() {
                this.$refs.simpleRules.validate()
                    .then(success => {
                        if (success) {

                            let data = {
                                code: this.code,
                                discount_coupon_type: this.type._id,
                                discountNumber: this.amount,
                                usagePerUserQuantity: this.usagePerUserQuantity ? this.usagePerUserQuantity : null,
                                usagePerCouponQuantity: this.usagePerCouponQuantity ? this.usagePerCouponQuantity : null,
                                expiryDate: this.expiryDate,
                                minimumCost: this.minimumCost ? this.minimumCost : null,
                                freeDelivery: this.freeDelivery,
                                productsOnOffer: this.productsOnOffer
                            };

                            axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon/create`, data)
                                .then(response => {

                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });

                                    this.code = '';
                                    this.type = null;
                                    this.amount = 0;
                                    this.usagePerUserQuantity = null;
                                    this.usagePerCouponQuantity = null;
                                    this.expiryDate = null;
                                    this.minimumCost = null;
                                    this.freeDelivery = false;
                                    this.productsOnOffer = false;

                                    this.$refs['simpleRules'].reset();

                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al crear el cupón de descuento.';
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });

                        }
                    });
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>